export enum PaymentType {
  BankTransfer = 'Bank Transfer',
  OnlinePayment = 'Online Payment',
  Cash = 'Cash',
  Tabby = 'tabby',
  Tamara = 'Tamara',
  RewaaPay = 'Rewaa Pay',
  MultiPayment = 'Multi Payment',
  PromotionalCredit = 'Promotional Credit',
  TapPayment = 'Tap Payment',
}

export enum CustomPaymentType {
  AlJazeeraBank = 'Al Jazeera Bank',
  AlRajhiBank = 'Al Rajhi Bank',
  BankTransfer = 'Bank Transfer',
  Cash = 'Cash',
  CreditCard = 'Credit Card',
  PromotionalCredit = 'Promotional credit',
  MultiPayment = 'Multi Payment',
  RewaaPay = 'Rewaa Pay',
  RiyadBank = 'Riyad Bank',
  SAIBBank = 'SAIB Bank',
  SaudiNationalBank = 'Saudi National Bank (SNB)',
  Tabby = 'Tabby',
  Tamara = 'Tamara',
  TapPayment = 'Tap Payment',
}

export const paymentTypeToCustomPaymentTypeMap: Record<
  PaymentType,
  CustomPaymentType | undefined
> = {
  [PaymentType.BankTransfer]: CustomPaymentType.BankTransfer,
  [PaymentType.OnlinePayment]: CustomPaymentType.CreditCard,
  [PaymentType.Cash]: CustomPaymentType.Cash,
  [PaymentType.Tabby]: CustomPaymentType.Tabby,
  [PaymentType.Tamara]: CustomPaymentType.Tamara,
  [PaymentType.RewaaPay]: CustomPaymentType.RewaaPay,
  [PaymentType.MultiPayment]: CustomPaymentType.MultiPayment,
  [PaymentType.TapPayment]: CustomPaymentType.TapPayment,
  [PaymentType.PromotionalCredit]: CustomPaymentType.PromotionalCredit,
};

export enum ShippingAddressTypes {
  Delivery = 'delivery',
  PickUp = 'pick-up',
}

export enum ChargesType {
  Hardware = 'hardware',
  Service = 'service',
}

export enum CheckOutFlows {
  SubscribeToAPlan = 'subscribe-plan',
  RenewPlan = 'renew-plan',
  UpgradePlan = 'upgrade-plan',
  AddAppsAndLocations = 'add-apps-locations',
  AddCharges = 'add-charges',
  AddServices = 'add-services',
  ChargeBeeWebhook = 'chargebee-webhook',
  licenseKeyPlan = 'license-key-plan',
}

export enum SubscriptionProductTypes {
  Plan = 'plan',
  Location = 'location',
  Application = 'application',
  Charge = 'charge',
}

export enum SubscriptionItemTypes {
  Plan = 'plan',
  Addon = 'addon',
  Charge = 'charge',
}
