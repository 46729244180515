import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SecurityContext,
  SimpleChanges,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { tap, timer, Subscription, first } from 'rxjs';
import {
  AutoUnsubscribe,
  AutoUnsubscribeI,
} from 'src/app/shared/decorators/auto-unsubscribe';
import { Intercom } from 'ng-intercom';
import { DomSanitizer } from '@angular/platform-browser';
import { CustomToastService } from '../../../../shared/services/custom-toast.service';
import { LoginDetails } from '../../../auth.models';
import { AuthService } from '../../../services/auth.service';
import { ConfirmationCodeValidator } from '../../confirm/validators/confirmationCodeValidator';
import { LocalStorageService } from '../../../../core/services/local-storage.service';
import { LocalStorageKey } from '../../../../shared/constants';
import {
  MerchantService,
  ResendConfirmationCodeResponse,
} from '../../../services/merchant.service';
import { TimerService } from '../../../services/timer.service';
import {
  DeliveryMediumType,
  UserType,
} from '../../../../shared/types/auth.types';
import { UserAuthenticationService } from '../../../services/user-authentication.service';
import { OnboardingScreens } from '../register-v2.component';

@Component({
  selector: 'rw-verify-otp',
  templateUrl: './verify-otp.component.html',
  styleUrls: ['./verify-otp.component.scss'],
})
@AutoUnsubscribe
export class VerifyOtpComponent implements AutoUnsubscribeI, OnChanges {
  subscriptionRefs;

  fGroup: UntypedFormGroup;

  numberForm: UntypedFormGroup;

  UserType = UserType;

  isShowSpinner = false;

  isShowInternalSpinner = false;

  errMessage: string;

  merchantSignUpInterval$ = timer(2500, 1500);

  count = 0;

  subscription: Subscription = undefined;

  changeLang: string;

  lang: string;

  timerVisible = false;

  unverifiedUser = false;

  vButtonVisibility = false;

  registerData: any;

  previousRegisterData: any;

  loginInProcess = false;

  message = '';

  loggedIn = false;

  countdownSeconds = 0;

  confirmationOTP = '';

  translationPrefix = 'confirmRegistration';

  medium: string;

  POLLING_LIMIT = 15;

  enterYourCodeMessage: string;

  otpFormControl: FormControl<number | null>;

  deliveryMedium: DeliveryMediumType;

  deliveryMediumEnum = DeliveryMediumType;

  retryLimitReachedForSms = false;

  retryLimitReachedForEmail = false;

  isMediumChanged = false;

  registrationQueryParams: {
    email: string;
    type: string;
    cc: string;
    phone: string;
    resend: boolean;
  };

  startTimerValue = 30;

  editPhoneModalVisible = false;

  internalResend = false;

  @Input() activeScreen: OnboardingScreens;

  @Input() signUpForm: UntypedFormGroup;

  @Input() passwordForm: UntypedFormGroup;

  @Output() completion = new EventEmitter();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private merchantService: MerchantService,
    private router: Router,
    private userAuthenticationService: UserAuthenticationService,
    private intercom: Intercom,
    private activatedRoute: ActivatedRoute,
    private toaster: CustomToastService,
    public translate: TranslateService,
    private timerService: TimerService,
    private localStorageService: LocalStorageService,
    private sanitizer: DomSanitizer,
  ) {
    this.lang = this.localStorageService.getItem<string>(
      LocalStorageKey.Language,
    );
    translate.use(this.lang);
    if (this.lang === 'en') {
      this.changeLang = 'ar';
    } else {
      this.changeLang = 'en';
    }
    this.otpFormControl = new FormControl(
      null,
      Validators.compose([Validators.required]),
    );

    this.numberForm = this.formBuilder.group({
      phone: [''],
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.activeScreen?.currentValue === OnboardingScreens.VerifyOtp) {
      this.initialize();
    }
  }

  initialize(): void {
    this.registerData = this.registerData ?? {
      companyName: this.signUpForm.get('companyName').value,
      phone: this.signUpForm.get('phone').value,
      licenseKey: this.signUpForm.get('licenseKey').value,
      selectedCountry: this.signUpForm.get('selectedCountry').value,
      password: this.passwordForm.get('password').value,
    };
    this.previousRegisterData = this.getRegisterDataFromLocalStorage();
    if (this.registerData) {
      this.storeRegisterDataInLocalStorage(this.registerData);
    }

    this.createForm();
    const email = this.signUpForm.get('email').value;
    const type =
      this.signUpForm.get('selectedCountry').value.countryKey === 'SA'
        ? UserType.Gcc
        : UserType.NonGcc;
    const countryCode = this.signUpForm.get('selectedCountry').value.code;
    const phone = this.signUpForm.get('phone').value;
    const resend = !!this.sanitizer.sanitize(
      SecurityContext.HTML,
      this.activatedRoute.snapshot.queryParams?.resend,
    );

    this.fGroup.controls.email.reset({ value: email, disabled: false });
    this.registrationQueryParams = {
      email,
      type,
      cc: countryCode,
      phone,
      resend,
    };

    this.getMessageToShowAndSetMediumType(email, countryCode, phone, type);

    const isDataModified =
      JSON.stringify(this.registerData) !==
      JSON.stringify(this.previousRegisterData);

    if (isDataModified) {
      this.timerService.stopCountdown();
    }

    if (resend || this.internalResend) {
      this.resendConfirmationV2(email, phone);
    } else {
      this.initResendTimer(email);
    }

    const url = new URL(window.location.href);
    url.searchParams.delete('resend');
    window.history.replaceState({}, '', url.toString());
    this.internalResend = false;

    this.timerService
      .isTimerActive()
      .pipe(
        tap((timerEnded) => {
          this.timerVisible = timerEnded;
        }),
      )
      .subscribe();

    this.timerService
      .currentTimeRemaining()
      .pipe(
        tap((timeRemaining) => {
          this.countdownSeconds = timeRemaining;
        }),
      )
      .subscribe();
  }

  get email(): AbstractControl | null {
    return this.fGroup.get('email');
  }

  get phone(): string {
    return this.registrationQueryParams?.phone;
  }

  get smsAvailable(): boolean {
    return this.registrationQueryParams?.type === UserType.Gcc;
  }

  private initResendTimer(email: string): void {
    this.subscriptionRefs = this.merchantService
      .getResendCount(email)
      .subscribe((totalResendCount) => {
        if (totalResendCount === 0) {
          this.startTimerValue = 30;
          this.timerService.startTimerV2(this.startTimerValue);
        } else if (totalResendCount) {
          const resendTimer = this.getTimerValueBasedOnTotalResendCount(
            totalResendCount - 1,
          );
          this.startTimerValue = resendTimer;
          this.timerService.startTimerV2(resendTimer);
        }
      });
  }

  startIntercom(): void {
    this.intercom.show();
  }

  private getMessageToShowAndSetMediumType(
    email: string,
    countryCode: string,
    phone: string,
    type: string,
  ) {
    if (type === UserType.Gcc) {
      this.message = `${this.translationPrefix}.phoneMsg`;
      this.medium = `${countryCode}${phone}`;
      this.deliveryMedium = DeliveryMediumType.SMS;
    } else if (type === UserType.GccWithoutPh) {
      this.message = `${this.translationPrefix}.phoneMsg2`;
      this.medium = phone;
      this.deliveryMedium = DeliveryMediumType.SMS;
    } else {
      this.message = `${this.translationPrefix}.emailMsg`;
      this.medium = email;
      this.deliveryMedium = DeliveryMediumType.EMAIL;
    }
  }

  switchLang(lang: string): void {
    this.authService.switchLanguage(lang);
  }

  createForm(): void {
    this.fGroup = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      code: [
        '',
        [Validators.required, ConfirmationCodeValidator.cannotContainSpace],
      ],
    });
  }

  getOTP(event: string): void {
    this.confirmationOTP = event;
    this.errMessage = null;
    if (this.confirmationOTP.length === 6) {
      this.vButtonVisibility = true;
    } else {
      this.vButtonVisibility = false;
    }
  }

  isRetryDisabled(): boolean {
    return (
      this.timerVisible ||
      (this.deliveryMedium === DeliveryMediumType.EMAIL &&
        this.retryLimitReachedForEmail) ||
      (this.deliveryMedium === DeliveryMediumType.SMS &&
        this.retryLimitReachedForSms)
    );
  }

  shouldShowRetryLimitReachedMessage(): boolean {
    const nonGccUserEmailLimitReached =
      this.retryLimitReachedForEmail &&
      this.registrationQueryParams?.type !== UserType.Gcc;

    const gccUserLimitReached =
      this.registrationQueryParams?.type === UserType.Gcc &&
      this.retryLimitReachedForSms &&
      this.retryLimitReachedForEmail;

    return (
      !this.timerVisible && (nonGccUserEmailLimitReached || gccUserLimitReached)
    );
  }

  isRetryDisabledForEmail(): boolean {
    return this.timerVisible || this.retryLimitReachedForEmail;
  }

  isRetryDisabledForSms(): boolean {
    return this.timerVisible || this.retryLimitReachedForSms;
  }

  checkMerchantSignUpStatus(email: string): void {
    this.subscriptionRefs = this.merchantService
      .getMerchantSignUpStatus(email)
      .subscribe(async (isSigningUp) => {
        if (!isSigningUp) {
          this.subscription.unsubscribe();
          if (this.loggedIn) return;
          this.loggedIn = true;
          if (!this.registerData?.password) {
            this.router.navigate(['/login']);
            return;
          }
          const { password } = this.registerData;
          await this.userAuthenticationService.login(
            new LoginDetails(email, password),
          );
          this.router.navigateByUrl('/onboarding');
        } else if (this.count > this.POLLING_LIMIT) {
          this.subscription.unsubscribe();
          this.router.navigate(['/login']);
        } else {
          this.count += 1;
        }
      });
  }

  async confirm(): Promise<void> {
    this.isShowInternalSpinner = true;
    this.subscriptionRefs = this.merchantService
      .confirmSignup(this.email.value, this.confirmationOTP)
      .subscribe({
        next: async () => {
          if (!this.registerData?.password) {
            this.router.navigate(['/login']);
            return;
          }
          this.completion.emit();
          this.subscription = this.merchantSignUpInterval$.subscribe(() => {
            this.checkMerchantSignUpStatus(this.email.value);
          });
        },
        error: (err) => {
          if (err.code === 'UserNotFoundException') {
            this.errMessage = 'emailAlreadyUsed';
          } else if (err.error?.message?.includes('Code Invalid Or Expired')) {
            this.errMessage = 'invalidOtp';
          }
          this.isShowInternalSpinner = false;
        },
        complete: () => {
          this.isShowInternalSpinner = false;
        },
      });
  }

  setStartTimerBasedOnRetryCount(
    payload: ResendConfirmationCodeResponse,
  ): void {
    const totalResendCount =
      payload.resendCount[DeliveryMediumType.EMAIL] +
      payload.resendCount[DeliveryMediumType.SMS] -
      1;

    this.startTimerValue =
      this.getTimerValueBasedOnTotalResendCount(totalResendCount);
  }

  getTimerValueBasedOnTotalResendCount(totalResendCount: number): number {
    const startTimerValue = 30;
    const increment = 15;
    return Math.min(startTimerValue + increment * totalResendCount, 120);
  }

  checkRetryLimit(payload: ResendConfirmationCodeResponse): void {
    const totalResendCount = Object.values(payload.resendCount).reduce(
      (acc, curr) => acc + curr,
      0,
    );

    if (totalResendCount >= 5) {
      this.setRetryLimitReached();
    }
  }

  handleResendConfirmationSuccess(): void {
    this.isMediumChanged = false;
    this.timerService.startTimerV2(this.startTimerValue);
    this.errMessage = null;
    this.toaster.success(
      this.translate.instant('code has been re-sent successfully'),
    );
  }

  private setRetryLimitReached(): void {
    this.retryLimitReachedForEmail = true;
    this.retryLimitReachedForSms = true;
  }

  handleResendConfirmationErrorForLimitExceeded(
    payload?: ResendConfirmationCodeResponse,
  ): void {
    this.toaster.error(
      this.translate.instant(`${this.translationPrefix}.limitExceededMessage`),
    );

    if (!payload) {
      this.setRetryLimitReached();
    }
  }

  resendConfirmationV2(email: string, phone: string): void {
    this.isShowSpinner = true;
    this.subscriptionRefs = this.merchantService
      .resendConfirmationCodeV2(
        email,
        phone ?? '',
        this.deliveryMedium,
        this.isMediumChanged,
      )
      .subscribe({
        next: (value) => {
          this.setStartTimerBasedOnRetryCount(value);
          this.checkRetryLimit(value);
          if (value.success) {
            this.handleResendConfirmationSuccess();
          } else {
            this.handleResendConfirmationErrorForLimitExceeded(value);
          }
        },
        error: (err) => {
          if (err.error?.message === 'LimitExceededException') {
            this.handleResendConfirmationErrorForLimitExceeded();
          } else {
            this.errMessage = err.message;
          }
          this.isShowSpinner = false;
        },
        complete: () => {
          this.isShowSpinner = false;
        },
      });
  }

  private storeRegisterDataInLocalStorage(data: object): void {
    const twoMinutesInMilliseconds = 120000;
    const expiryTime = new Date().getTime() + twoMinutesInMilliseconds;

    this.localStorageService.setItem(LocalStorageKey.PreviousRegisterData, {
      ...data,
      expiryTime,
    });
  }

  private getRegisterDataFromLocalStorage(): object {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const registerData = this.localStorageService.getItem<any>(
      LocalStorageKey.PreviousRegisterData,
    );

    if (!registerData) {
      return null;
    }

    if (new Date().getTime() > registerData.expiryTime) {
      this.localStorageService.removeItem(LocalStorageKey.PreviousRegisterData);
      return null;
    }

    delete registerData.expiryTime;

    return registerData;
  }

  public toggleDeliveryMedium(): void {
    this.deliveryMedium =
      this.deliveryMedium === DeliveryMediumType.EMAIL
        ? DeliveryMediumType.SMS
        : DeliveryMediumType.EMAIL;

    const { email, cc, phone, type } = this.registrationQueryParams;

    if (this.deliveryMedium === DeliveryMediumType.EMAIL) {
      this.message = `${this.translationPrefix}.emailMsg`;
      this.medium = email;
    } else if (type === UserType.Gcc) {
      this.message = `${this.translationPrefix}.phoneMsg`;
      this.medium = `${cc}${phone}`;
    } else {
      this.message = `${this.translationPrefix}.phoneMsg2`;
      this.medium = phone;
    }

    this.isMediumChanged = true;
    this.resendConfirmationV2(this.email.value, phone);
  }

  /** @deprecated */
  resendConfirmation(email: string): void {
    this.isShowSpinner = true;
    this.subscriptionRefs = this.merchantService
      .resendConfirmationCode(email)
      .subscribe({
        next: () => {
          this.timerService.startTimer(25);
          this.errMessage = null;
          this.toaster.success(
            this.translate.instant('code has been re-sent successfully'),
          );
        },
        error: (err) => {
          if (
            err.error?.name === 'LimitExceededException' ||
            err.error?.message === 'LimitExceededException'
          ) {
            this.toaster.error(
              this.translate.instant(
                `${this.translationPrefix}.limitExceededMessage`,
              ),
            );
          } else {
            this.errMessage = err.message;
          }
          this.isShowSpinner = false;
        },
        complete: () => {
          this.isShowSpinner = false;
        },
      });
  }

  navigateToRegister(): void {
    this.router.navigate(['/register'], {
      queryParams: {
        ...this.activatedRoute.snapshot.queryParams,
        registered: true,
      },
      state: { ...this.registerData },
    });
  }

  updatePhoneInComponent(newPhone: string): void {
    this.signUpForm.get('phone').setValue(newPhone);
    if (this.registerData) this.registerData.phone = newPhone;
  }

  updatePhoneInUrl(newPhone: string): void {
    const url = new URL(window.location.href);
    if (url.searchParams.has('phone')) {
      const type = url.searchParams.get('type');
      let phoneStr = newPhone;
      if (type === UserType.GccWithoutPh) {
        phoneStr = `+${this.signUpForm.get('selectedCountry').value.code}${newPhone}`;
      }
      url.searchParams.set('phone', phoneStr);
    }
    window.history.replaceState({}, '', url.toString());
  }

  updatePhoneNumber(newPhone: string): void {
    this.editPhoneModalVisible = false;
    if (!newPhone) return;

    this.isShowSpinner = true;
    this.authService
      .updateCognitoNumber({
        email: this.signUpForm.get('email').value,
        phoneNumber:
          this.signUpForm.get('selectedCountry').value.code + newPhone,
      })
      .pipe(first())
      .subscribe({
        next: () => {
          this.internalResend = true;
          this.updatePhoneInUrl(newPhone);
          this.updatePhoneInComponent(newPhone);
          this.initialize();
        },
        error: (err) => {
          this.isShowSpinner = false;
        },
        complete: () => {
          this.isShowSpinner = false;
        },
      });
  }

  showEditPhoneModal(): void {
    this.editPhoneModalVisible = true;
  }

  restartSignUp(): void {
    this.router.navigate([], { replaceUrl: true }).then(() => {
      window.location.reload();
    });
  }
}
