import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Components
import { RegisterV2Component } from './components/self-onboarding/register-v2.component';
import { ConfirmRegistrationComponent } from './components/confirm/confirm-registration.component';
import { ActivateUserComponent } from './components/activate-user/activate-user.component';

// Services
import { UnAuthenticatedGuardService } from './services/unauthenticated-auth.guard.service';
import { LoginV2Component } from './components/login-v2/login-v2.component';
import { ResetPasswordV2Component } from './components/forget-password/reset-password-v2/reset-password-v2.component';
import { OnboardingGuideComponent } from './components/onboarding-guide/onboarding-guide.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginV2Component,
    canActivate: [UnAuthenticatedGuardService],
  },
  {
    path: 'register',
    component: RegisterV2Component,
    canActivate: [UnAuthenticatedGuardService],
  },
  {
    path: 'reset-password',
    component: ResetPasswordV2Component,
    canActivate: [UnAuthenticatedGuardService],
  },
  {
    path: 'confirmation',
    component: ConfirmRegistrationComponent,
    canActivate: [UnAuthenticatedGuardService],
  },
  {
    path: 'activate-user/:activatedCode',
    component: ActivateUserComponent,
    canActivate: [UnAuthenticatedGuardService],
  },
  { path: '**', redirectTo: '/' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
