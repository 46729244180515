import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';
import { HttpStatusCode } from '@rewaa-team/rewaa-common';
import { environment } from '../../../environments/environment';
import {
  Answer,
  GetBusinessSectorDTO,
  Question,
} from '../../getting-to-know-rewaa/models/user-onboarding.models';
import { LocalStorageKey, Sector } from '../constants';
import { LocalStorageService } from '../../core/services/local-storage.service';
import { CompanyType } from './constants/business-types';

@Injectable()
export class UserOnboardingService {
  private apiUrl = environment.onboardingApiPath;

  private businessSector$ = new BehaviorSubject<Sector>(null);

  private businessCompanyType$ = new BehaviorSubject<CompanyType>(null);

  private userHasAnswers = true;

  constructor(
    private readonly http: HttpClient,
    private readonly localStorageService: LocalStorageService,
  ) {}

  getOnboardingQuestions(): Observable<Question[]> {
    return this.http.get<Question[]>(`${this.apiUrl}/questions`);
  }

  postAnswers(answers: Answer[]): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/answers`, {
      answers,
    });
  }

  async fetchBusinessTypeFromApi(): Promise<GetBusinessSectorDTO> {
    return firstValueFrom(
      this.http.get<GetBusinessSectorDTO>(
        `${this.apiUrl}/answers/question-type`,
      ),
    );
  }

  private updateBusinessType(businessAnswers: GetBusinessSectorDTO): void {
    this.businessSector$.next(businessAnswers?.SECTOR);
    this.businessCompanyType$.next(businessAnswers?.COMPANY_TYPE);
  }

  private saveBusinessTypeToLocalStorage(): void {
    this.localStorageService.setItem(LocalStorageKey.BusinessType, {
      SECTOR: this.businessSector$.value,
      COMPANY_TYPE: this.businessCompanyType$.value,
    } as GetBusinessSectorDTO);
  }

  private getBusinessTypeFromLocalStorage(): void {
    const businessAnswers = this.localStorageService.getItem(
      LocalStorageKey.BusinessType,
      null,
    );
    if (!businessAnswers) return;
    this.updateBusinessType(businessAnswers);
    this.userHasAnswers = !!this.businessSector$.value;
  }

  private clearBusinessTypeFromLocalStorage(): void {
    this.localStorageService.removeItem(LocalStorageKey.BusinessType);
  }

  async refreshBusinessType(): Promise<void> {
    this.businessSector$.next(null);
    this.businessCompanyType$.next(null);
    this.clearBusinessTypeFromLocalStorage();
    await this.fetchBusinessType();
  }

  async fetchBusinessType(): Promise<void> {
    this.getBusinessTypeFromLocalStorage();
    if (this.businessSector$.value !== null) return;
    try {
      const businessAnswers = await this.fetchBusinessTypeFromApi();
      this.updateBusinessType(businessAnswers);
      this.saveBusinessTypeToLocalStorage();
      this.userHasAnswers = true;
    } catch (error) {
      this.updateBusinessType(null);
      if (error.error.statusCode === HttpStatusCode.NotFound) {
        this.saveBusinessTypeToLocalStorage();
        this.userHasAnswers = false;
      }
    }
  }

  doesUserHaveAnswers(): boolean {
    return this.userHasAnswers;
  }

  isBusinessSectorRetail(): boolean {
    return this.businessSector$.value === Sector.Retail;
  }

  getBusinessCompanyType(): CompanyType {
    return this.businessCompanyType$.value;
  }
}
