export enum HttpStatusCode {
  Ok = 200,
  Created = 201,
  Accepted = 202,
  BadRequest = 400,
  Unauthorized = 401,
  NotFound = 404,
  NotAcceptable = 406,
  Conflict = 409,
  UnprocessableEntity = 422,
  InternalServerError = 500,
  NotImplemented = 501,
  BadGateway = 502,
  ServiceUnavailable = 503,
  GatewayTimeout = 504,
}

export enum HttpStatusText {
  Ok = 'Ok',
  Created = 'Created',
  Accepted = 'Accepted',
  BadRequest = 'Bad Request',
  Unauthorized = 'Unauthorized',
  NotFound = 'Not Found',
  NotAcceptable = 'Not Acceptable',
  Conflict = 'Conflict',
  UnprocessableEntity = 'Unprocessable Entity',
  InternalServerError = 'Internal Server Error',
  NotImplemented = 'Not Implemented',
  BadGateway = 'Bad Gateway',
  ServiceUnavailable = 'Service Unavailable',
  GatewayTimeout = 'Gateway Timeout',
}

export enum HeaderKey {
  AmazonTraceId = 'x-amzn-trace-id',
}
